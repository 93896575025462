import { getCurrentInstance, computed } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState, createNamespacedHelpers } from 'vuex'

export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) return console.warn('useStore must be used after setup')
  return vm?.proxy?.$store
}
// functionName
const vuexToStringMap = new Map([
  [mapState, 'mapState'],
  [mapGetters, 'mapGetters'],
  [mapMutations, 'mapMutations'],
  [mapActions, 'mapActions'],
])

// 验证及传参处理
// 没有传入命名空间的情况下，转为空字符串
const normalizeNamespace = (fn, type) => {
  return (namespace, map) => {
    if (map && typeof namespace !== 'string') {
      throw new Error(
        `${type}传入的命名空间不能为非string类型,当前值类型为${typeof namespace}，请检查`,
      )
    }
    if (typeof namespace !== 'string') {
      map = namespace
      namespace = ''
    }
    
    return fn(namespace, map)
  }
}

// 核心函数，取出来改变this指向，然后返回
const map = (namespace, keyMap, vuxFun) => {
  const $store = useStore()
  
  let mapObj = null
  if(namespace) {
    const module = createNamespacedHelpers(namespace)
    const functionName = vuexToStringMap.get(vuxFun)

    mapObj = module[functionName](keyMap)
  } else {
    mapObj = vuxFun(keyMap)
  }

  // 对于state和getters，需要将其转换为计算属性，因为是响应式的
  const isStateOrGetters = [mapState, mapGetters].includes(vuxFun)

  const result = {}
  for (const key in mapObj) {
    const fn = mapObj[key].bind({ $store })
    result[key] = isStateOrGetters ? computed(fn) : fn
  }
  return result
}

export const useMapState = normalizeNamespace((namespace, keyMap) => map(namespace, keyMap, mapState), 'useMapState')

export const useMapGetters = normalizeNamespace((namespace, keyMap) => map(namespace, keyMap, mapGetters), 'useMapGetters')

export const useMapMutation = normalizeNamespace((namespace, keyMap) => map(namespace, keyMap, mapMutations), 'useMapMutation')

export const useMapActions = normalizeNamespace((namespace, keyMap) => map(namespace, keyMap, mapActions), 'useMapActions')

/**
 * vue3 升级，$attrs 中包含了 style 和 class，需要过滤掉，只返回自定义属性
 * https://v3-migration.vuejs.org/zh/breaking-changes/attrs-includes-class-style.html
 * @param {*} $attrs 
 * @returns 
 */
export const useMapAttrs = ($attrs = {}) => {
  try {
    const { style, class: className, ...arg } = $attrs || {}
    return arg || {}
  } catch (error) {
    console.log('useMapAttrs error:', error);
    return $attrs
  }
}
