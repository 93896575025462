<template>
  <coupon
    v-if="isShowCoupon"
    :is-new-user="isNewUser"
    :item="coupon"
    v-bind="componentAttrs"
    :coupon-type="couponType"
    :for-revisit-component="forRevisitComponent"
    :class="getClass"
    :showNewTransportationWay="showNewTransportationWay"
    :showWraperImg="showWraperImg"
    :theme-config="themeConfig"
  />
</template>

<script>
import { defineComponent } from 'vue'
import coupon from './coupon.vue'
import { souceFormat } from './utils'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'
export default defineComponent({
  name: 'CouponItem',
  components: { coupon },
  props: {
    isNewUser: {
      type: Boolean,
      default: false,
    },
    item: {
      // 优惠券信息
      type: Object,
      default: () => {},
    },
    couponType: {
      type: String,
      default: '',
    },
    forRevisitComponent: {
      type: Boolean,
      default: false,
    },
    // TR-13844 邮费全免券支持配置可用的运输方式，
    // 仅个人中心券列表（未使用+过期）、下单页券列表（可用+不可用）、下单页买券券列表在coupon-item组件中配置
    showNewTransportationWay: {
      // 券包展示新的交通运输方式
      type: Boolean,
      default: false
    },
    // 自定义颜色
    themeConfig: {
      type: Object,
      default: () => { }
    },
    showWraperImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShowCoupon: false,
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    coupon() {
      return this.souceFormat(this.item) || {}
    },
    getClass() {
      const class1 =
        this.couponType == 'revisit-coupon' && 'has-border_top-color'
      return [class1]
    },
  },
  watch: {
    coupon: {
      handler(val) {
        if (val?.apply_for) {
          this.isShowCoupon = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    souceFormat: souceFormat,
  },
})
</script>

<style lang="less">
.has-border_top-color {
  font-family: 'SF UI Display';
  // color: @sui_color_promo;
  /*sh:begin*/
  color: #fa6338;
  /*rw:begin*/
  color: #fc4070;
}

.has-border_top-color::before {
  height: 4px;
  // background-color: @sui_color_promo;
  /*sh:begin*/
  background-color: #fa6338;
  /*rw:begin*/
  background-color: #fc4070;
}
</style>
