<template>
  <section>
    <!-- 优惠券券码 -->
    <li 
      v-if="isNewStyle && showCouponCode && item.coupon"
      :id="`code-${item.coupon}`"
      class="code-range"
    >
      <!-- <span>{{ language.SHEIN_KEY_PWA_16658 }}: {{ (item.coupon || "").toUpperCase() }}</span> -->
      <span v-if="incouponpkg">{{ language.SHEIN_KEY_PWA_16658 }}: {{ (expanded ? afterCode : item.coupon || "").toUpperCase() }}</span>
      <span v-else>{{ language.SHEIN_KEY_PWA_16658 }}: {{ (item.coupon || "").toUpperCase() }}</span>
      <span
        v-if="item.is_bind === 0"
        v-expose="getCopyAnalysis('1-6-1-145')"
        v-tap="getCopyAnalysis('1-6-1-144')"
        :data-clipboard-text="(item.coupon || '').toUpperCase()"
        class="copy-code-btn" 
        @click="copyCode"
      >
        {{ language.SHEIN_KEY_PWA_25296 }}
      </span>
    </li>
    <!-- 非绑定券使用说明提示 -->
    <li
      v-if="item.is_bind === 0 && !isOnlyTime"
      :id="`unbind-${item.coupon}`"
      class="unbind-range"
    >
      <span>
        {{ language.SHEIN_KEY_PWA_25106 }}
      </span>
    </li>

    <!-- 日期范围提示 ，如果是用户回归提示，不需要日期提示revisit-coupon-->
    <li
      v-if="isShowDataRange && couponType != 'revisit-coupon' && !isNewUser"
      :id="`data-${item.coupon}`"
      class="data-range"
      :class="{ 'countdown-range': directionType == 'row' && isShowCountdownFlag }"
      tabindex="0"
    >
      <template v-if="item.apply_for == '101'">
        {{ language.SHEIN_KEY_PWA_26359 }} {{ rangeTime[1] }}
      </template>
      <template v-else-if="directionType == 'row' && isShowCountdownFlag">
        <coupon-countdown
          :language="language"
          :item="item"
          :direction-type="directionType"
        />
      </template>
      <template v-else>
        <span v-if="couponType != 'revisit-coupon'">{{ rangeTime[0] }} ~ {{ rangeTime[1] }}</span>
        <time-tips
          v-if="headType == 'head-row' && !forRevisitComponent"
          :item="item"
          :page="page"
          v-bind="componentAttrs"
          :language="language"
          :head-type="headType"
        />
      </template>
    </li>
  
    <template v-if="!isOnlyTime">
      <!-- 适用商品范围提示 Start -->
      <!-- 商品传递scid之后需要可点击, 跳转+埋点  item.scId-->
      <li
        v-if="isBesideShipCoupon"
        :id="`use-${item.coupon}`"
        v-expose="exposeAnalyse(item)"
        v-tap="{id: '1-25-1-9', data: {coupon_id: item.id}}"
        class="use-range"
        :class="[couponType == 'revisit-coupon' && 'revisit-use-range', showSclink && 'has-sc-link' ]"
        tabindex="0" 
        @click="gotoScLink(item.scId)"
      >
        <!-- 非自营店铺券 -->
        <span v-if="item.coupon_dimension == '3' && !isSheinStore">
          {{ 
            isMultMall
              ? template(mallName, storeName, language[getOtherStoreTips()])
              : template(storeName, language[getOtherStoreTips()])
          }}
        </span>
        
        <template v-else>
          <!-- 全部商品--1 -->
          <span v-if="item.apply_for == '1'">
            {{ getForAllTips() }}
          </span>

          <!-- 部分品类--3 -->
          <template v-else-if="item.apply_for == '3'">
            <span style="max-height: inherit;">
              {{ applyForTextMall({ mallName, category: item.category_name }) }}
            </span>
          </template>

          <!-- 邮费全免--5 + 减最低邮费--6 -->
          <span v-else-if="['5', '6'].includes(item.apply_for)">
            <!-- v-if="item.shipping_free_limit != 0" -->
            {{
              htmlDecode({
                text: getForFreeTips()
              })
            }}  
          </span>

          <!-- 部分店铺的平台券 -->
          <span v-else-if="item.apply_for == '8'">
            {{ getPlatformStoreTips() }}
          </span>
          
          <!-- 指定运输方式减运费 -->
          <span v-else-if="item.apply_for == '9'">
            {{
              htmlDecode({
                text: template(mallName, language[getPrimeTips()])
              })
            }} 
          </span>

          <!-- 选品页面--7、部分商品--2 -->
          <span v-else>
            {{
              htmlDecode({
                text: getForSelectTips()
              })
            }}
          </span>
          <!-- 用户回归弹窗类型即将过期提示调整到这里,如果长度为1则不显示，大于1的时候显示，而且是用户回归弹窗类型 -->
          <time-tips
            v-if="item.coupon_rule.length>1 && couponType == 'revisit-coupon'"
            :item="item"
            :page="page"
            :language="language"
            :coupon-type="couponType"
          />
        </template>
      </li>

      <!-- 卡 bin 优惠券的使用提示 -->
      <li
        v-if="showCardCouponTips && item.card?.card_name"
        :id="`card-${item.coupon}`"
        class="card-range"
      >
        {{ template(item.card.card_name, language.SHEIN_KEY_PWA_31340) }}
      </li>

      <!-- 指定运输方式的券的使用提示 -->
      <li
        v-if="item.apply_for == '9' && showShippingCouponTips"
        :id="`designated-shiping-${item.coupon}`"
        class="designated-shiping-range"
      >
        {{ language.SHEIN_KEY_PWA_29128 }}
      </li>


      <!-- 支持的运输方式 -->
      <li
        v-if="shippingMethdsTips"
        :id="`shipping-methods-${item.coupon}`"
        class="shipping-methods"
      >
        <span>
          {{ shippingMethdsTips }}
        </span>
      </li>

      <!-- 支付方式限制提示 -->
      <li
        v-if="item.payment_title.length"
        :id="`payment-${item.coupon}`"
        class="payment-range"
        tabindex="0"
      >
        <span
          v-if="paymentLimitTitles"
        >
          {{ paymentLimitTitles }}
        </span>
      </li>

      <!-- 付费会员/超省卡指定产品包可用提示 -->
      <li
        v-if="[58, 59].includes(+item.real_type_id) && rightProductTips"
        :id="`right-product-${item.coupon}`"
        class="right-product-range"
      >
        <span>
          {{ rightProductTips }}
        </span>
      </li>

      <!-- 付费会员/超省卡指定周期产品包可用提示 -->
      <li
        v-if="[58, 59].includes(+item.real_type_id) && rightCycleTips"
        :id="`right-cycle-${item.coupon}`"
        class="right-cycle-range"
      >
        <span>
          {{ rightCycleTips }}
        </span>
      </li>

      <!-- 多阶梯提示 -->
      <li
        v-if="hasMultiCouponRule"
        :id="`other-${item.coupon}`"
        class="other-range"
        tabindex="0"
      >
        <span
          v-if="otherOfferOptions"
        >
          {{ htmlDecode({ text: language.SHEIN_KEY_PWA_17515}) }}{{ otherOfferOptions }}
        </span>
      </li>

      <!-- 法务文案提示 -->
      <li
        v-if="isShowPostAmountFlag"
        :id="`post-amount-${item.coupon}`"
        class="post-amount-range"
      >
        <span>
          {{ language.SHEIN_KEY_PWA_22049 }}
        </span>
      </li>
    </template>
  </section>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
const { host, langPath } = gbCommonInfo
import { htmlDecode, timeTransformer, template } from '@shein/common-function'
import { MESSAGE_DATE, COUPON_TIME_FORMAT_BY_ABT } from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import timeTips from './timeTips.vue'
import CouponCountdown from './couponCountdown.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { shippingApplyForEnum, platformStoreTypeEnum } from './enum.js'
import Clipboard from 'clipboard'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

daEventCenter.addSubscriber({ modulecode: '1-25-1' })
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
const { SiteUID } = gbCommonInfo
export default defineComponent({
  name: 'CouponTips',
  directives: {
    expose,
    tap,
  },
  components: { timeTips, CouponCountdown },
  emits: ['gotoScLink'],
  props: {
    hasLinkToCate: {
      type: Boolean,
      default: false
    },
    exposeId: {
      type: String,
      default: ''
    },
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: { // 优惠券信息
      type: Object,
      default: () => {},
    },
    language: { // 多语言
      type: Object,
      default: () => {},
    },
    isMultMall: { // 是否是多mall
      type: Boolean,
      default: true
    },
    isOnlyTime: {
      type: Boolean,
      default: false
    },
    headType: { // 券展示形式
      type: String,
      default: ''
    },
    isShowPostAmount: { // 是否展示法务文案
      type: Boolean,
      default: false
    },
    showDateRange: { // 是否展示日期
      type: Boolean,
      default: true
    },
    isShowCountdownFlag: {
      type: Boolean,
      default: false
    },
    showShippingCouponTips: { // 是否展示运费劵使用提示
      type: Boolean,
      default: false
    },
    directionType: { // 多档券排列顺序
      type: String,
      default: 'column'
    },
    couponType: {
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false
    },
    isNoChangeTime: { // 是否直接展示时间，不需要转化
      type: Boolean,
      default: false
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    // showNewTransportationWay:{
    //   // 券包展示新的交通运输方式
    //   type: Boolean,
    //   default: false
    // }
    showCouponCode: { // 是否展示优惠券券码
      type: Boolean,
      default: true,
    },
    incouponpkg: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: [String, Number],
      default: ''
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
    // 卡 bin 优惠券提示
    showCardCouponTips: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      mallName: '', // mall名称
      storeName: '', // 店铺名称
      isSheinStore: false, // 是否是shein自营店铺
      isOptionsOver: false,
      isPaymentOver: false,
      couponTimeChangeAbt: false,
      afterCode: ''
    }
  },

  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    showSclink(){
      return this.item.scId && this.hasLinkToCate
    },
    // 运输方式
    shippingMethdsTips () {
      // if (this.item.real_type_id != '53') return ''
      if (!this.item.shipping_list?.length) return ''
      return this.template(this.item.shipping_list
        .map((_) => _.shipping_method_name)
        .join(', '), this.language.SHEIN_KEY_PWA_22611)
    },
    isShowPostAmountFlag () {
      if (!this.isShowPostAmount) return false
      if (this.item.coupon_rule.length > 1) return true
      if (+this.item.coupon_rule?.[0]?.min?.amount == 0) return false
      return true
    },
    maxRange () {
      return this.headType == 'head-old' ? 1 : 3
    },
    // 是否展示日期范围
    isShowDataRange () {
      if (!this.showDateRange) return
      let { end_date } = this.item || {}
      if (!end_date) return true
      end_date = (typeof end_date == 'string') ? end_date.replace(/-/g, '/') : end_date
      let __END_TIME__ = Number(end_date) ? Number(end_date) * 1000 : new Date(end_date).getTime()
      // 如果后端返回的时间是2100-12-31 23:59 或者 对应时间的时间戳  不展示优惠券有效期
      let __DEFAULT_TIME__ = new Date('2100/12/31 23:59').getTime()
      return __END_TIME__ < __DEFAULT_TIME__
    },
    // 是否展示商品范围提示
    isBesideShipCoupon () {
      return ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(this.item.apply_for)
    },
    // 是否展示其他规则提示
    hasMultiCouponRule () {
      return ((this.item.coupon_rule.length > this.maxRange) && (this.headType == 'head-row')) || this.headType == 'head-old'
    },
    // 支付方式文案
    paymentLimitTitles () {
      return this.template(
        this.item.payment_title.map(v => v.title).filter(v => v).join(', '),
        this.language.SHEIN_KEY_PWA_17155
      )
    },
    paymentLines () {
      let max = 2
      // 适用范围，运费券没有
      if (this.isBesideShipCoupon) {
        max--
      }
      return max
    },
    optionsLines () {
      let max = 2
      // 适用范围，运费券没有
      if (this.isBesideShipCoupon) {
        max--
      }
      // 支付限制
      if (this.item.payment_title.length) {
        max--
      }
      return max
    },
    // 是否是折扣券
    isDiscountCoupon() {
      return !['5', '6'].includes(this.item.apply_for) && ['2', '3', '5'].includes(this.item.coupon_type_id)
    },
    otherOfferOptions () {
      return this.item.coupon_rule
        .slice(this.maxRange)
        .map(val => {
          if (this.isDiscountCoupon) {
            // 折扣券
            const text = this.template(this.percentFormat(val.value.amount), val.min.amount, val.coupon_gift_id ? this.language.SHEIN_KEY_PWA_18335 : this.language.SHEIN_KEY_PWA_17523)
            return this.htmlDecode({ text })
          }
          // 其他类型券
          const otherText = this.template(val.value.amountWithSymbol, val.min.amount, val.coupon_gift_id ? this.language.SHEIN_KEY_PWA_18335 : this.language.SHEIN_KEY_PWA_17523)
          return this.htmlDecode({ text: otherText })
        })
        .join(', ')
    },

    rangeTime(){
      if (this.isNoChangeTime) return [this.item.no_trans_start_date, this.item.no_trans_end_date]
      let [startTime, endTime] = [+this.item.start_date * 1000,  +this.item.end_date * 1000]
      let result =  [startTime, endTime].map(time => {
        if(!time) return time
        if(this.couponTimeChangeAbt) {
          return timeTransformer({
            time,
            sDateMap: COUPON_TIME_FORMAT_BY_ABT,
            defaultCode: 'F-5'
          })
        }
        return `${timeTransformer({
          time,
          sDateMap: MESSAGE_DATE,
        })} ${timeTransformer({ time, defaultCode: 'G-2' })}`
      })
      return result
    },
    // 第三方店铺信息
    thirdStoreInfo() {
      return this.item?.thirdStoreInfo || {}
    },
    // 付费会员/超省卡指定产品包可用提示
    rightProductTips() {
      const languageMap = {
        '58': this.language.SHEIN_KEY_PWA_28756,
        '59': this.language.SHEIN_KEY_PWA_28744
      }
      const { real_type_id, rights_info = {} } = this.item || {}
      const { right_apply = 0, product_code_info_list = [] } = rights_info
      if (+right_apply === 1 && product_code_info_list.length) {
        return this.template(
          product_code_info_list[0].product_code_multi,
          languageMap[real_type_id]
        )
      }
      return ''
    },
    // 付费会员/超省卡指定周期产品包可用提示
    rightCycleTips() {
      const languageMap = {
        '58': this.language.SHEIN_KEY_PWA_28756,
        '59': this.language.SHEIN_KEY_PWA_28745
      }
      const rightCycleMap = {
        1: 'Monthly',
        2: 'Quarterly',
        3: 'Annually',
        4: 'Semi-Annually',
        5: 'Weekly',
        6: 'Semi-Monthly',
        7: 'Special Membership'
      }
      const { real_type_id, rights_info } = this.item || {}
      const { right_apply = 0, rights_cycles = [] } = rights_info
      if (+right_apply === 2 && rights_cycles.length) {
        return this.template(
          rightCycleMap[rights_cycles[0]],
          languageMap[real_type_id]
        )
      }
      return ''
    }
  },

  created () {
    this.mallName = this.item?.mall?.mall_name_multi || ''
    this.storeName = this.item?.store?.store_name_multi || ''
    this.isSheinStore = +this.item?.store?.store_code == 1
    this.isNewStyle && this.handleCodeAfterExpand()
  },

  async mounted() {
    const { CouponTimeFormatChange } = await getUserAbtData()
    this.couponTimeChangeAbt = CouponTimeFormatChange?.param?.CouponTimeFormat === 'TimeFormatChange'
  },
  methods: {
    template: template,
    htmlDecode: htmlDecode,
    handleCodeAfterExpand() {
      let beforeExpandCouponCode = window.localStorage.getItem('beforeExpandCouponCode')
      let realBind = JSON.parse(window.localStorage.getItem('realBind'))
      // 如果是点了膨胀，显示膨胀后的券码
      if(beforeExpandCouponCode && realBind) {
        let storageAfterCode = window.localStorage.getItem('afterCodes') // JSON.stringify(Array.from(afterCodes.entries()))
        this.afterCode = new Map(JSON.parse(storageAfterCode)).get(this.item.coupon) || this.item.coupon
      }else {
        this.afterCode = this.item.coupon
      }
    },
    copyCode(){
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        const tip = this.template(
          this.item.coupon || '',
          this.language.SHEIN_KEY_PWA_24956
        )
        SToast(tip, 3000)
        clipboard.destroy()
      })
    },
    exposeAnalyse(item){
      if(item.scId) {
        return {
          id: '1-25-1-8',
          data: {
            coupon_id: item.id
          }
        }
      }else {
        return {}
      }
    },
    gotoScLink(ScId){
      if(this.showSclink){
        let [mallCode, couponDimension] = ['', '']
        const { coupon } = this.item
        if (this.isMultMall) {
          mallCode = this.item?.mall?.mall_code || ''
          couponDimension = this.item?.coupon_dimension || ''
        }
        let query = `ShiwTip=1&CouponCode=${coupon.toLowerCase()}`
        if (couponDimension == '2') {
          query += `&mallCode=${mallCode}`
        }
        this.$emit('gotoScLink', coupon)
        location.href = `${host}${langPath}/coupon-sc-${ScId}.html?${query}`
      }
    },
    // 获取第三方店铺的店铺券提示
    getOtherStoreTips () {
      const { apply_for } = this.item
      if (apply_for == '1') {
        return this.isMultMall ? 'SHEIN_KEY_PWA_20922' : 'SHEIN_KEY_PWA_23527'
      }
      if (apply_for == '2') {
        return this.isMultMall ? 'SHEIN_KEY_PWA_23526' : 'SHEIN_KEY_PWA_23528'
      }
      return 'SHEIN_KEY_PWA_20922'
    },
    /**
     * 获取全部商品提示
     * coupon_type_id == '2' 原价百分比 : 金额或者应付价百分比
     */
    getForAllTips () {
      const { mallName, language, isMultMall } = this
      let tips = []
      const { coupon_type_id = '', filter_rule = 0 } = this.item
      const type = coupon_type_id == '2' ? 1 : 0
      switch (this.item.coupon_dimension) {
        case '1':
          tips = [['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910'], ['SHEIN_KEY_PWA_20909', 'SHEIN_KEY_PWA_21348']]
          break
        case '2':
          tips = [['SHEIN_KEY_PWA_20918', 'SHEIN_KEY_PWA_20919'], ['SHEIN_KEY_PWA_21316', 'SHEIN_KEY_PWA_21393']]
          break
        case '3': //
          if (isMultMall) {
            tips = [['SHEIN_KEY_PWA_26436', 'SHEIN_KEY_PWA_26435'], ['SHEIN_KEY_PWA_26437', 'SHEIN_KEY_PWA_26438']]
            return template(mallName, language[tips[type][filter_rule]])
          } else {
            tips = [['SHEIN_KEY_PWA_26154', 'SHEIN_KEY_PWA_26332'], ['SHEIN_KEY_PWA_26174', 'SHEIN_KEY_PWA_26334']]
            return language[tips[type][filter_rule]]
          }
        default:
          tips = [['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910'], ['SHEIN_KEY_PWA_20909', 'SHEIN_KEY_PWA_21348']]
          break
      }
      return template(mallName, language[tips[type][filter_rule]])
    },
    // 获取部分商品提示
    getForSelectTips () {
      const { mallName, language, thirdStoreInfo, isMultMall } = this
      let tips = ''
      switch (this.item.coupon_dimension) {
        case '1':
          tips = 'SHEIN_KEY_PWA_20910'
          break
        case '2':
          tips = 'SHEIN_KEY_PWA_20919'
          break
        case '3':
          tips = isMultMall ? 'SHEIN_KEY_PWA_26625' : 'SHEIN_KEY_PWA_26626'
          return isMultMall ? template(thirdStoreInfo?.storesName, language[tips]) : language[tips]
        default:
          tips = 'SHEIN_KEY_PWA_20910'
          break
      }
      return template(mallName, language[tips])
    },
    // 获取免邮券提示
    getForFreeTips () {
      const { mallName, language, isMultMall } = this
      let tips = []
      const filterRule = this.item?.filter_rule || 0
      // 免邮券适用场景
      const shippingApplyFor = this.item?.shippingApplyFor || -1
      // 券适用店铺类型
      const platformStoreType = this.item?.platform_store?.type || -1

      switch (this.item.coupon_dimension) {
        case '1':
          tips = ['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910']
          if (
            // 部分店铺
            shippingApplyFor == shippingApplyForEnum.PART 
            &&
            // 仅自营店铺
            platformStoreType == platformStoreTypeEnum.SELF
          ) {
            tips = ['SHEIN_KEY_PWA_23420', 'SHEIN_KEY_PWA_26331']
          }
          break
        case '2':
          tips = ['SHEIN_KEY_PWA_20918', 'SHEIN_KEY_PWA_20919']
          break
        case '3':
          if (isMultMall) {
            tips = ['SHEIN_KEY_PWA_26436', 'SHEIN_KEY_PWA_26435']
          } else {
            tips = ['SHEIN_KEY_PWA_26154', 'SHEIN_KEY_PWA_26332']
            return language[tips[filterRule]]
          }
          break
        default:
          tips = ['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910']
          break
      }
      return template(mallName, language[tips[filterRule]])
    },
    // 付费会员券商品提示
    getPrimeTips () {
      let tips = []
      const filterRule = this.item?.filter_rule || 0
      switch (this.item.coupon_dimension) {
        case '1':
          tips = ['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910']
          break
        case '2':
          tips = ['SHEIN_KEY_PWA_20918', 'SHEIN_KEY_PWA_20919']
          break
        default:
          tips = ['SHEIN_KEY_PWA_20908', 'SHEIN_KEY_PWA_20910']
          break
      }
      return tips[filterRule]
    },
    /**
     * 获取部分店铺的平台券提示
     * coupon_type_id == '2' 原价百分比 : 金额或者应付价百分比
     * platform_store.type 1-仅自营店铺；2-仅第三方店铺；3-自营+第三方
     */
    getPlatformStoreTips () {
      const { thirdStoreInfo, language } = this
      let tips = []
      const { coupon_type_id = '', filter_rule = 0, platform_store = {}, coupon_dimension } = this.item
      // coupon_type_id == '2' 为原价折
      const type = coupon_type_id == '2' ? 1 : 0
      switch (+platform_store?.type) {
        case 1:
          tips = ['pwus', 'rwmus'].includes(SiteUID)
            ? [['SHEIN_KEY_PWA_23412', 'SHEIN_KEY_PWA_26331'], ['SHEIN_KEY_PWA_23414', 'SHEIN_KEY_PWA_26333']]
            : [['SHEIN_KEY_PWA_23420', 'SHEIN_KEY_PWA_26331'], ['SHEIN_KEY_PWA_23422', 'SHEIN_KEY_PWA_26333']]
          return language[tips[type][filter_rule]]
        case 2:
          if (thirdStoreInfo?.storeNum > 2) { // 大于两个店铺
            tips = [['SHEIN_KEY_PWA_26499', 'SHEIN_KEY_PWA_26497'], ['SHEIN_KEY_PWA_26503', 'SHEIN_KEY_PWA_26501']]
            return this.template(thirdStoreInfo?.storesName, thirdStoreInfo?.storeNum, language[tips[type][filter_rule]])
          } else {
            tips = [['SHEIN_KEY_PWA_26500', 'SHEIN_KEY_PWA_26498'], ['SHEIN_KEY_PWA_26504', 'SHEIN_KEY_PWA_26502']]
            return this.template(thirdStoreInfo?.storesName, language[tips[type][filter_rule]])
          }
        case 3:
          tips = [['SHEIN_KEY_PWA_20910', 'SHEIN_KEY_PWA_20910'], ['SHEIN_KEY_PWA_21348', 'SHEIN_KEY_PWA_21348']]
          break
        case 4:
          // 劵包场景配置
          if (this.page === 'couponPackage') {
            return language?.SHEIN_KEY_PWA_20910
          }
          // 自营店铺的品类券：coupon_dimension=1 & apply_for=8 & platform_store.type=4
          if (coupon_dimension == 1) {
            tips = [
              // 非原价折：[未命中剔品规则, 命中剔品规则]
              ['SHEIN_KEY_PWA_28876', 'SHEIN_KEY_PWA_28875'],
              // 原价折
              ['SHEIN_KEY_PWA_28874', 'SHEIN_KEY_PWA_28873']
            ]
            break
          }
          // eslint-disable-next-line no-fallthrough
        default:
          tips = ['pwus', 'rwmus'].includes(SiteUID)
            ? [['SHEIN_KEY_PWA_23412', 'SHEIN_KEY_PWA_23413'], ['SHEIN_KEY_PWA_23414', 'SHEIN_KEY_PWA_23416']]
            : [['SHEIN_KEY_PWA_23420', 'SHEIN_KEY_PWA_23421'], ['SHEIN_KEY_PWA_23422', 'SHEIN_KEY_PWA_23423']]
          break
      }
      return language[tips[type][filter_rule]]
    },
    percentFormat (num) {
      var newNum = (num * 100) / 100
      var percentNum = newNum + '%'
      return percentNum
    },
    applyForTextMall ({ mallName, category }) {
      const { thirdStoreInfo, language, template, isMultMall } = this
      const tpmLang = this.getForSelectTips()
      let categoryText = template(mallName, language[tpmLang])
      // TR-25501改动：不再进行品类拼接
      if(!this.isNewStyle && category?.length) {
        categoryText += ':' + this.categoryText(category, '')
      }

      if (isMultMall) {
        return template(thirdStoreInfo?.storesName, language?.SHEIN_KEY_PWA_26625) + categoryText
      } else {
        return language?.SHEIN_KEY_PWA_26626 + categoryText
      }
      
    },
    // 具体品类拼接
    categoryText (category, parentCategoryTxt) {
      let categoryTxt = ''
      category.forEach(item => {
        if(item?.children?.length) {
          parentCategoryTxt += item.cat_name_multi + '-'
          categoryTxt = this.categoryText(item.children, parentCategoryTxt)
        } else {
          categoryTxt += parentCategoryTxt + item.cat_name_multi + '; '
        }
      })
      return categoryTxt
    },
    setData (key, value) {
      this[key] = value
    },
    getCopyAnalysis(id){
      return {
        id,
        data: {
          location: 0,
          id: this.item.coupon
        }
      }
    }
  },
})
</script>

<style lang="less" scoped>
.revisit-use-range{
  display: flex;
  justify-content: space-between;
}

.use-range.has-sc-link {
  color: #000;
  span {
    position: relative;
  }
  span::after{
    position: absolute;
    right: -12px;
    font-size: 12px;
    transform: scale(0.9);
    content: '>';
    color: #000;
  }
}
.use-range.has-sc-link::before{
  color: #000;
}
.code-range > .copy-code-btn{
  color: #2D68A8;
  padding-left: 0.213rem;
  flex: 1;
  text-align: left;
}
</style>
