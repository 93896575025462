<template>
  <div
    class="coupon-expansion"
    v-bind="componentAttrs"
  >
    <img 
      :src="`${PUBLIC_CDN}/pwa_dist/images/expansion_coupon/${expansionBgImage}`" 
      :style="imgStyle"
    />
    <span
      ref="text"
      class="coupon-expansion__text"
      :style="textStyle"
    >
      {{ text }}
    </span>
    <span class="coupon-expansion__sub-text"> {{ subText }} </span>
  </div>
</template>


<script>
import { defineComponent } from 'vue'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'
const { PUBLIC_CDN } = gbCommonInfo
export default defineComponent({
  name: 'CouponExpansion',
  props: {
    text: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    isCssRight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      PUBLIC_CDN
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    imgStyle() {
      return {
        width: this.text?.length < 8 ? '2.2667rem' : '3.421rem'
      }
    },
    textStyle() {
      return {
        width: this.text?.length < 8 ? '2.2rem' : '3.2rem'
      }
    },
    expansionBgImage() {
      if (this.text?.length < 8) {
        return this.isCssRight ? 'expansion-btn-short-ar-10ddda7675.png' : 'expansion-btn-short-d1b286f8c0.png'
      }
      return this.isCssRight ? 'expansion-btn-ar-503fcbc021.png' : 'expansion-btn-d5f8928443.png'
    }
  },
})
</script>

<style lang="less">
.coupon-expansion {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  animation: breathe 0.9s infinite;
  touch-action: none;
  text-align: right;
  img {
    position: absolute;
    width: 2.32rem;
    height: 0.74667rem;
    right: -0.1rem;
  }
  &__text {
    font-family: SF UI Display;
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 0.3735rem;
    font-size: 0.32rem;
    color: #FFF;
    font-weight: 600;
  }
  &__sub-text {
    position: absolute;
    right: -0.1867rem;
    top: -0.35rem;
    white-space: nowrap;
    font-family: SF UI Text;
    color: #FFF;
    line-height: 0.32rem;
    font-size: 0.32rem;
    background: linear-gradient(90deg, #FF58B2 -0.11%, #FF2525 99.89%);
    border-radius: 0.32rem;
    padding: 0.10667rem 0.21335rem;
    transform: scale(.7);
    transform-origin: right;
  }
}
@keyframes breathe {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(0.99);
  }
}
</style>
