<template>
  <div
    :id="`coupon-item-${item.keyId}`" 
    v-expose="exposeAnalyse(item)" 
    class="c-coupon-item__wrapper"
    :class="{
      expired: isExpired || isDisabled || item.coupon_status == '3',
      'prime-coupon': item.real_type_id == '53' && !isGreenThemeConfig,
      'prime-coupon__max': item.times > 100000,
      'prime-coupon_white': item.real_type_id == '53' && isWhiteBackground && !isGreenThemeConfig,
      'prime-coupon__logo': item.real_type_id == '53' && !showWraperImg,
      'c-coupon-item__new-user': isNewUser,
      'c-coupon-item__saver-coupon': item.real_type_id == '55' && !isGreenThemeConfig,
      'c-coupon-item__expansion-coupon': isExpansionBg,
      'c-coupon-item__for-prime': item.real_type_id == '58' && !isGreenThemeConfig,
      'c-coupon-item__for-saver': item.real_type_id == '59' && !isGreenThemeConfig,
      'hide-coupon-header': hideCouponHeader
    }" 
    :style="{ 
      ...midStyle, 
      ...couponBackgroundStyle 
    }"
  >
    <img 
      v-if="couponBackgroundLogo"
      class="c-coupon-item__logo"
      :src="couponBackgroundLogo"
    />

    <!-- 券主体信息 -->
    <component
      :is="headType"
      :id="`coupon-head-${item.keyId}`"
      :is-new-user="isNewUser"
      :item="item"
      :is-satisfied-range="isSatisfiedRange"
      :page="page"
      :language="language"
      v-model:is-head-old="isHeadOld"
      :is-show-free-gift="isShowFreeGift"
      :show-coupon-code="showCouponCode"
      :direction-type="directionType"
      :is-expired="isExpired"
      v-bind="componentAttrs"
      :show-coupondown="showCoupondown"
      :is-show-countdown-flag="isShowCountdownFlag"
      :coupon-type="couponType"
      :for-revisit-component="forRevisitComponent"
      :update-style="updateStyle"
      :is-new-style="isNewStyle"
      :is-green-theme="isGreenThemeConfig"
      :show-wraper-img="showWraperImg"
    >
      <template #main>
        <!-- 券主体信息 -->
        <coupon-main
          v-if="couponType != 'revisit-coupon' && !forRevisitComponent && !isNewUser"
          :expanded="expanded"
          :item="item"
          :language="language"
          :show-coupon-code="showCouponCode"
          :is-new-style="isNewStyle"
          v-bind="componentAttrs"
        />
      </template>
      <template #handle>
        <!-- 优惠券操作或者过期提示 -->
        <coupon-handle
          :item="item"
          :language="language"
          :is-expired="isExpired"
          :expired-type="expiredType"
          :is-mult-mall="isMultMall"
          :expand-abt-info="expandAbtInfo"
          :is-expansion-bg="isExpansionBg"
          :is-show-countdown-flag="isShowCountdownFlag"
          :direction-type="directionType"
          :is-show-pay-success-btn="isShowPaySuccessBtn"
          :page="page"
          v-bind="componentAttrs"
        />
      </template>
    </component>

    <template v-if="hasDateRange">
      <div
        v-if="isNewUser"
        :class="{'mid-line__is-new-user': isNewUser}"
        :style="{
          backgroundImage: `url(${PUBLIC_CDN}/pwa_dist/images/coupon_box/coupon_center-2f9ba124b9.png)`
        }"
      ></div>
      
      <!-- 优惠券中间分隔线 -->
      <div
        v-else
        class="coupon-mid-line"
      >
        <div></div>
      </div>

      <!-- 券内容文案 -->
      <div :class="{ body: true, expand: isExpand || setExpand }">
        <div
          class="tips-wrapper bg-wrapper"
          :style="{
            backgroundImage:
              item.times > 100000 && !(isExpired || item.coupon_status == '3')
                ? `url(${PUBLIC_CDN}${GB_cssRight ? '/pwa_dist/images/sui_img_bg_UTshippingcoupons_bottom_ar-be5c4b3cbe.png' : '/pwa_dist/images/sui_img_bg_UTshippingcoupons_bottom-5494cdf59b.png'})`
                : 'none'
          }"
        >
          <!-- 文案列表 -->
          <div
            :id="`coupon-desc-content-${item.keyId}`"
            class="coupon-desc-content coupon-desc-container"
            :class="[ (forRevisitComponent||couponType == 'revisit-coupon')&&'coupon-desc-content-no-maxhight']"
          >
            <coupon-tips
              :is-new-user="isNewUser"
              :item="item"
              :page="page"
              :language="language"
              :is-mult-mall="isMultMall"
              :head-type="headType"
              :is-show-countdown-flag="isShowCountdownFlag"
              :show-coupon-code="showCouponCode"
              :direction-type="directionType"
              :coupon-type="couponType"
              :for-revisit-component="forRevisitComponent"
              :showNewTransportationWay="showNewTransportationWay"
              :is-new-style="isNewStyle"
              :show-date-range="showDateRange"
              v-bind="componentAttrs"
            />

            <div
              v-if="isShowPaySuccessBtn"
              class="check-btn"
              @click="viewCouponList"
            >
              {{ language.SHEIN_KEY_PWA_17535 }} > 
            </div>
          </div>

          <!-- 收起/展开icon -->
          <span
            v-if="isTipIconShow"
            class="c-icon-tip"
            @click.stop="handleClick"
          >
            <i class="iconfont icon-circlecollapse-12px"></i>
          </span>
        </div>
      </div>
    </template>

    <!-- 券试用次数 -->
    <div
      v-if="item.times > 1 && item.times < 100000 && !getAOrB"
      class="count"
      :style="countStyle"
      tabindex="0"
    >
      <span v-if="item.is_expand == 1"> {{ language.SHEIN_KEY_PWA_23693 }} </span> x{{ item.times }}
    </div>
    <div
      v-if="(item.times == 1 && item.is_expand == 1 && !getAOrB)"
      class="count"
      :style="countStyle"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PWA_23693 }}
    </div>

    <!-- 无限免邮券提示 -->
    <div
      v-if="item.times > 100000 && !getAOrB"
      class="count max-count"
      :style="countStyle"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PWA_22386 }}
    </div>

    <!-- 最近12h获取的券提示--Newly Get角标 -->
    <div
      v-if="isShowNewlyGetFlag && !getAOrB"
      class="coupon__newly-get"
    >
      New
    </div>

    <template v-if="hdHeight && !isNewUser">
      <!-- 半圆分割线 -->
      <div
        class="coupon-cycle-border coupon-cycle-border__left"
        :style="{ top: `calc(${hdHeight} - .5px)` }"
      ></div>
      <div
        class="coupon-cycle-border coupon-cycle-border__right"
        :style="{ top: `calc(${hdHeight} - .5px)` }"
      ></div>
    </template>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import couponHandle from './couponHandle.vue'
import couponTips from './couponTips.vue'
import couponMain from './couponMain.vue'
import headOld from './header/old.vue'
import headColumn from './header/column.vue'
import headRow from './header/row.vue'
import { getTextLline } from './utils'

const { GB_cssRight, PUBLIC_CDN } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter'
import { htmlDecode, template, throttle } from '@shein/common-function'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { PageSourceEnum } from './enum.js'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

export default defineComponent({
  name: 'CouponIndex',
  directives: {
    expose,
  },
  components: {
    couponHandle,
    couponTips,
    couponMain,
    headOld,
    headColumn,
    headRow
  },
  emits: ['triggerCollapse', 'triggerExpand', 'view-coupon-list', 'update-over-handle'],
  props: {
    //  修改判断是否是超省卡的条件
    // isSaverCoupon: {
    //   type: Boolean,
    //   default: false
    // },
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: { // 优惠券信息
      type: Object,
      default: () => { },
    },
    language: { // 多语言
      type: Object,
      default: () => { },
    },
    // 券曝光事件id
    exposeId: {
      type: String,
      default: ''
    },
    // 券曝光abt数据
    abtest: {
      type: String,
      default: ''
    },
    isShowPaySuccessBtn: {
      type: Boolean,
      default: false
    }, //按钮显示隐藏
    showCouponCode: { // 是否展示优惠券券码
      type: Boolean,
      default: true,
    },
    isDisabled: { // 是否禁用
      type: Boolean,
      default: false,
    },
    isExpired: { // 是否失效
      type: Boolean,
      default: false
    },
    expiredType: {
      type: String,
      default: 'expired',
    },
    expanded: { // 券提示信息折叠约定flag
      type: [String, Number],
      default: 'false'
    },
    directionType: { // 多档券排列顺序
      type: String,
      default: 'column'
    },
    isSatisfiedRange: { // 是否满足档位展示拼接文案free gift
      type: Boolean,
      default: false
    },
    isShowFreeGift: { // 是否展示赠品提示free gift
      type: Boolean,
      default: true
    },
    isWhiteBackground: {
      type: Boolean,
      default: true
    },
    isShowNewly: {
      type: Boolean,
      default: false
    },
    showCoupondown: {
      type: Boolean,
      default: false
    },
    // 是否隐藏头部红条
    hideCouponHeader: {
      type: Boolean,
      default: false
    },
    updateStyle: {
      type: Boolean,
      default: false
    },
    couponType: {
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false
    },
    showNewTransportationWay: {
      // 券包展示新的交通运输方式
      type: Boolean,
      default: false
    },
    expandAbtInfo: {
      // 膨胀劵包abt
      type: Object,
      default: () => {}
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    // 自定义颜色
    themeConfig: {
      type: Object,
      default: () => {}
    },
    showDateRange: {
      type: Boolean,
      default: true
    },
    showWraperImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      resizeObserverOfCoupon: null,
      GB_cssRight,
      PUBLIC_CDN,
      isTipIconShow: false,
      isTipIconShowClass: false,
      setExpand: true,
      isHeadOld: false,
      childLength: 0,
      hdHeight: 0,
      isMultMall: false,
      heights: [],
      visibility: 'hidden'
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    // 是否膨胀
    isExpansionBg(){
      const { expandStatus } = this.item || {}
      const { CodeExpand = '', CodeExpandType } = this.expandAbtInfo || {}
      return (expandStatus == 'btn' || expandStatus == 'seal') && CodeExpand !== 'off' && CodeExpandType == 'A'
    },
    headType() {
      // 针对新用户显示的券包，显示新头部
      if(this.isNewUser){
        if ((this.item?.coupon_rule?.length == 1)) {
          return 'head-old'
        }else {
          // 新用户券包多档头部显示row排列
          return 'head-row'
        }
      }else {
        // 会员券保持老头部样式
        if (this.item?.real_type_id == '53') {
          return 'head-old'
        }
        if ((this.item?.coupon_rule?.length == 1 || this.isHeadOld)) {
          return 'head-old'
        }
        return `head-${this.directionType}`
      }
    },
    // 优惠券数量样式
    countStyle () {
      if (this.item.expandStatus == 'subscript' || this.item.is_expand == 1) {
        return {
          width: 'auto',
          padding: '0 .16rem'
        }
      }
      if (this.item.times > 100000) return { width: 'inherit', padding: '0 .16rem' }
      return this.item.times > 99 ? { width: ('x' + this.item.times).length * 0.7 + 'em' } : {}
    },
    isExpand() {
      return this.expanded == this.item.keyId
    },
    midStyle() {
      if (!this.hasDateRange) {
        return {
          visibility: this.visibility,
          ...this.themeConfig,
        }
      }
      if(!this.isNewUser) {
        // 非新客才下面
        return {
          maskImage: `
            radial-gradient(
              circle at 6px calc(${this.hdHeight} + 6px),
              transparent 6px,
              #fff 6px
            )
          `,
          visibility: this.visibility,
          border: this.couponType == 'revisit-coupon' && 'none',
          ...this.themeConfig,
        }
      }
      return {}
    },
    isGreenThemeConfig(){
      return this.themeConfig?.['--themeColor'] == '#169E00' 
    },
    couponBackgroundStyle() {
      const urlMap = {
        // 会员会费券
        '58': {
          default: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_prime_bg-42a66cd895.png`,
          ar: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_prime_bg_ar-954e86bb79.png`
        },
        // 超省卡会费券
        '59': {
          default: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_saver_bg-db95f83547.png`,
          ar: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_saver_bg_ar-2a42f283e3.png`
        }
      }
      const url = GB_cssRight ? urlMap[this.item.real_type_id]?.ar : urlMap[this.item.real_type_id]?.default
      if (url) return { backgroundImage: `url(${url})` }
      return {}
    },
    couponBackgroundLogo() {
      const urlMap = {
        // 会员会费券
        '58': {
          default: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_prime_logo-f662d7291e.png`,
          ar: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_prime_logo_ar-1806c5381c.png`
        },
        // 超省卡会费券
        '59': {
          default: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_saver_logo-5fb6840436.png`,
          ar: `${PUBLIC_CDN}/pwa_dist/images/coupon/coupon_for_saver_logo_ar-bd3efeb3bb.png`
        }
      }
      const url = GB_cssRight ? urlMap[this.item.real_type_id]?.ar : urlMap[this.item.real_type_id]?.default
      if (url) return url
      return ''

    },
    isShowNewlyGetFlag() {
      if (this.isShowNewly) {
        const { bind_time } = this.item
        if (!bind_time) return false
        const nowDate = new Date().getTime()
        if (nowDate < bind_time * 1000 + 12 * 60 * 60 * 1000) {
          return true
        }
      }
      return false
    },
    // 是否展示倒计时
    isShowCountdownFlag() {
      if (!this.showCoupondown) return false
      const { end_date, timeStatus, start_date } = this.item
      let [
        timeStep,
        newDate,
        endDate,
        startDate
      ] = [
        72 * 60 * 60 * 1000, //倒计时72小时
        new Date().getTime(), // 当前时间
        end_date * 1000, // 有效期结束时间
        start_date * 1000, // 有效期开始时间
      ]

      if (timeStatus === '1') {
        // 不在倒计时范围内 距离开始时间 72 小时内
        return startDate - newDate <= timeStep
      }

      // 不在倒计时范围内 距离到期时间 72 小时内
      if (newDate + timeStep <= endDate) return false
      return true
    },
    // 是否有使用时间
    hasDateRange () {
      if (this.item?.start_date && this.item?.end_date) {
        return true
      }
      return false
    },
    getAOrB(){
      return this.couponType == 'revisit-coupon' || this.forRevisitComponent
    },
    isNewStyle() {
      return [
        PageSourceEnum.CHECKOUT,
        PageSourceEnum.CART,
        PageSourceEnum.USER_COUPON
      ].includes(this.page)
    }
  },
  watch: {
    updateStyle(val) {
      if (val) {
        this.heights = []
        nextTick(() => {
          this.isTipIconShowFn()
          // 分割线高度
          this.hdHeight = $(`#coupon-item-${this.item.keyId} .head`).css('height')
        })
      }
    },
    isExpand: {
      handler(val) {
        if (!val) {
          this.noExpandUpdateStyle()
        }
      },
      immediate: true
    },
  },
  created() {
    this.getIsMultMall()
  },
  mounted() {
    nextTick(() => {
      try {
        let couponDom = document.querySelector(`#coupon-item-${this.item.keyId} .head`)
        if (!couponDom) return
        // 创建 ResizeObserver 实例
        this.resizeObserverOfCoupon = new ResizeObserver(throttle({
          func: entries => {
            for (var entry of entries) {
              try {
                if (entry.target.id != `coupon-head-${this.item.keyId}`) return
                const height = entry.target.getBoundingClientRect?.()?.height
                if (height === undefined) return
                this.isTipIconShowFn()
                // 分割线高度
                this.hdHeight = $(`#coupon-item-${this.item.keyId} .head`).css('height')
              } catch (error) {
                console.log('entry error', error)
              }
            }
          },
          wait: 300
        }))
        // 开始观察容器
        this.resizeObserverOfCoupon.observe(couponDom)
        if (this.couponType == 'revisit-coupon' || this.forRevisitComponent) {
          this.visibility = 'visible'
        }
        this.setExpand = false
      } catch (error) {
        console.log('ResizeObserver error', error)
        setTimeout(() => {
          // 用户回归弹窗A方案和B方案直接展示折叠部分内容
          if (this.couponType == 'revisit-coupon' || this.forRevisitComponent) {
            this.visibility = 'visible'
          } else {
            this.isTipIconShowFn()
          }
        }, 500)
        this.setExpand = false
        // 分割线高度
        setTimeout(() => {
          this.hdHeight = $(`#coupon-item-${this.item.keyId} .head`).css('height')
        }, 500)
      }
    })
  },
  methods: {
    htmlDecode: htmlDecode,
    template: template,
    exposeAnalyse(item) {
      const content_list = `${item.id}_${this.isShowCountdownFlag ? 'countdown' : 'interval'}`
      return {
        id: this.exposeId,
        data: {
          content_list: content_list,
          upgrade_type: !this.item.expandStatus ? '' : ['seal', 'subscript'].includes(this.item.expandStatus) ? 1 : 0,
          abtest: this.abtest,
          code: item.coupon
        }
      }
    },
    // 站点mall信息
    async getIsMultMall() {
      const { mallInfoForSite } = await getSiteMallInfo()
      this.isMultMall = mallInfoForSite?.length > 1
    },
    noExpandUpdateStyle () {
      nextTick(() => {
        const tipsHeight = this.heights.reduce((a, b) => a + b, 0)
        $(`#coupon-desc-content-${this.item.keyId}`).css('max-height', `calc(${tipsHeight}px + ${(this.heights.length - 1) * 0.107}rem)`)
      })
    },
    handleClick() {
      if (this.isExpand) {
        this.noExpandUpdateStyle()
        this.$emit('triggerCollapse')
        daEventCenter.triggerNotice({ daId: '1-25-1-3' })
      } else {
        $(`#coupon-desc-content-${this.item.keyId}`).css('max-height', 'none')
        this.$emit('triggerExpand', this.item.keyId)
        daEventCenter.triggerNotice({ daId: '1-25-1-2' })
      }
    },
    // 收起/展开icon
    isTipIconShowFn() {
      const ids = [
        'data', 
        'use', 
        'card',
        'payment', 
        'other', 
        'post-amount', 
        'shipping-methods', 
        'mall-info', 
        'unbind', 
        'right-product', 
        'right-cycle',
        'designated-shiping'
      ]
      // TR-25501 - 券码更换位置
      this.isNewStyle && ids.unshift('code')
      let rowNum = 0
      let flag = true
      let flag28 = false
      this.heights = []
      ids.forEach((id, index) => {
        const el = $(`#${id}-${this.item.coupon}`)
        if (el.length) {
          rowNum = rowNum + getTextLline(el)
          if (flag) {
            if (index == 1 && rowNum >= 3) {
              this.heights.push(28)
              flag = false
              flag28 = true
            } else if (index >= 2 && rowNum >= 3) {
              const sumHeight = this.heights.reduce((total, h) => total += h, 0)
              if(!flag28 && sumHeight <= 14) {
                this.heights.push(28)
                flag28 = true
              } else {
                this.heights.push(14)
              }
              flag = false
            } else if (rowNum <= 3 && el.height()) {
              this.heights.push(el.height())
            }
          }
        }
      })
      const tipsHeight = this.heights.reduce((a, b) => a + b, 0)
      $(`#coupon-desc-content-${this.item.keyId}`).css('max-height', `calc(${tipsHeight}px + ${(this.heights.length - 1) * 0.107}rem)`)
      this.isTipIconShow = rowNum > 3
      this.visibility = 'visible'
      this.$emit('update-over-handle', false)
    },
    viewCouponList () {
      this.$emit('view-coupon-list')
    }
  },
})
</script>

<style lang="less">
.c-coupon-item__expansion-coupon {
  background: #FFF3F9;
  border-color: #FFCFCF;
  .head {
    color: #FA3867;
  }
  &:before {
    background-color: #FA3867;
  }
}
.c-coupon-item__saver-coupon::before {
  background-color: #F82854;
}
.c-coupon-item__saver-coupon {
  border: 0.5px solid #FEE3ED;
  background: #FFF4F7;
  .head {
    color: #F82854;
    .shiping-coupon-tip {
      background-color: #FFD7E0;
    }
    .head-right {
      border-color: #F82854;
    }
    .coupon-countdown {
      color: #F82854;
    }
    .coupon-countdown__time > span {
      background-color: #F82854;
    }
  }
  .count {
    background-color: #F82854;
  }
  .count::before {
    border-right-color: #F82854;
    border-color: #F82854 transparent transparent;
  }
  .coupon-mid-line {
    &>div {
      background-image: linear-gradient(to right,
          #fecedb 0%,
          #fecedb 50%,
          transparent 50%);
      
    }
  }
  .body {
    li {
      color: rgba(128, 26, 48, 0.70);
      &::before {
        background: rgba(128, 26, 48, 0.70);
      }
    }
  }
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-coupon-item__new-user:last-child {
  margin-bottom: -0.25rem !important; /* stylelint-disable-line declaration-no-important */
}
.c-coupon-item__new-user {
  // 新客样式
  // box-shadow: 0 1px 2px #83422f;
  background: transparent;
  border: none;
  &:before {
    display: none;
  }

  .head-row.head,.head {
    padding: 0.21333333333333335rem 0.32rem 0;
    width: 6.8rem;
    min-height: 1.2266666666666666rem;
    height: auto;
    border-radius: 0.21333333333333335rem 0.21333333333333335rem 0 0;
    border: 0.02666666666666667rem solid #ff8360;
    border-bottom: none;
    background: #fffbf9;
  }
  .mid-line__is-new-user {
    width: 6.8rem;
    height: 0.38rem;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    margin: -1px 0;
    position: relative;
    z-index: 10;
  }
  .body {
    width: 6.8rem;
    min-height: 0.5333333333333333rem;
    height: auto;
    border-radius: 0 0 0.21333333333333335rem 0.21333333333333335rem;
    border: 0.02666666666666667rem solid #ff8360;
    border-top: none;
    background: #fffbf9;
    box-shadow: 0 1px 2px #83422f;
  }
  .coupon-title-warp {
    span{
      word-break: break-all;
      text-align: left;
      color: #FA6338 /*rw: #fc4070*/;
    }
  }
}

.c-coupon-item__wrapper {
  position: relative;
  display: block;
  border-top: 0;
  border: none;
  margin-bottom: 0.32rem;
  background: var(--bgColor, @sui_color_welfare_bg);
  mask-position: -6px;
  border-width: .5px;
  border-style: solid;
  border-color: var(--borderColor, #FFE2CF);
  border-top: 0;
  overflow: hidden;
  .coupon-cycle-border {
    width: 13px;
    height: 13px;
    background-color: var(--borderColor, #FFE2CF);
    border-radius: 50%;
    position: absolute;
    &__left {
      .left(-7px);
    }
    &__right {
      .right(-7px);
    }
  }
  // 顶部橙色
  &:before {
    content: "";
    position: absolute;
    height: 0.107rem;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--themeColor, @sui_color_welfare);
    z-index: @zindex-hack;
  }

  .count {
    position: absolute;
    .right(0);
    top: 0;
    height: 0.493rem;
    line-height: 0.493rem;
    width: 0.667rem;
    text-align: center;
    background-color: var(--themeColor, @sui_color_promo);
    color: #fff;
    z-index: @zindex-hack; /* stylelint-disable-line declaration-property-value-blacklist */
    &::before {
      content: "";
      position: absolute;
      .right(100%);
      top: 0;
      display: inline-block;
      border-width: 0.247rem 0.127rem;
      border-style: solid;
      border-color: transparent;
      border-top-color: var(--themeColor, @sui_color_promo);
      .border-r(0.107rem, var(--themeColor, @sui_color_promo));
    }
  }

  .coupon-mid-line {
    height: 0.32rem;
    display: flex;
    align-items: center;

    &>div {
      flex: 1;
      height: 2px;
      background-image: linear-gradient(to right,
          var(--midLineColor, #fff) 0%,
          var(--midLineColor, #fff) 50%,
          transparent 50%);
      background-size: 8px 2px;
      background-repeat: repeat-x;
    }
  }

  .head {
    .font-dpr(40px);
    line-height: 0.64rem;
    font-weight: bold;
    color: var(--themeColor, @sui_color_welfare);
    padding: 0.534rem 0.32rem .21rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .head-left {
      .margin-r(24px);
      overflow: hidden;
      flex: 1;
    }

    .head-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .tag-content {
        .font-dpr(24px);
        font-weight: normal;
        color: @sui_color_highlight;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }

    .coupon-stackable {
      .font-dpr(24px);
      display: inline-block;
      padding: 0px .16rem;
      color: @sui_color_link;
      background: @sui_color_note_light_bg;
      font-weight: 400;
    }

    p {
      &.coupon-tag-list{
         margin-top: 0.213rem;
      }
      .font-dpr(24px);
      line-height: 0.4rem;
      margin-top: 0.08rem;
      font-weight: normal;
      word-break: break-all;

      &.coupon-item {
        .flexbox();
        .space-between();
      }

      &.discount-price {
        color: @sui_color_highlight;
      }
    }

    .coupon-check {
      width: 0.5334rem;
      height: 0.5334rem;

      span {
        font-size: 0;
      }

      .unchecked {
        background-color: rgba(34, 34, 34, 0.2);
        display: block;
        height: 100%;
        border-radius: 50%;
      }

      .checked:after {
        /* stylelint-disable declaration-property-value-disallowed-list */
        content: "\e660";
        font-size: 0.5334rem;
        color: #222;
      }
    }

    .apply-btn,
    .expired-btn {
      text-align: center;
      color: white;
      border: 0;
      outline: 0;
    }

    .apply-btn {
      .font-dpr(24px);
    }

    .expired-btn {
      .font-dpr(20px);
      min-width: 1.48rem;
      height: 0.53rem;
      line-height: 0.53rem;
      color: #666;
      padding: 0 20/75rem;
    }

    &.unstart {
      opacity: 0.3;
    }
  }

  .header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body {
    position: relative;
    transition: all 0.2s;
    padding: 7/75rem 0.427rem 0.2667rem;

    .check-btn{
      position: absolute;
      right: 30/75rem;
      top: 60/750rem;
      color: #2D68A8;
    }

    &.expand {
      max-height: initial;

      .coupon-desc-content,
      p {
        max-height: initial;
      }

      li .over-text {
        display: block;
      }

      .c-icon-tip [class*="iconfont"] {
        transform: rotate(0deg)
          /*rtl:ignore*/
        ;
      }
    }

    .coupon-desc-content {
      max-height: 1.26rem;
      width: 96%;
      overflow: hidden;

      li {
        max-width: 7.82rem;
      }

      .data-range {
        max-width: 100%;
      }
    }
    .coupon-desc-content-no-maxhight{
      max-height: none;
      width: 100%;
    }

    .mult-height {
      max-height: 1.16rem;
    }

    .count-height {
      max-height: 1.38rem;
    }

    .count-height__mult {
      max-height: 1.36rem;
    }

    .c-icon-tip {
      position: absolute;
      .right(0.127rem);
      bottom: 0.2667rem;
      padding: 0;
      color: #999;
      line-height: 0.373rem;

      [class*="iconfont"] {
        display: block;
        .font-dpr(24px);
        transform: rotate(180deg)
          /*rtl:ignore*/
        ;
      }
    }

    li {
      .font-dpr(20px);
      line-height: 14px;
      color: #666;
      .padding-l(0.187rem);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        .left(0);
        .margin-l(-0.04rem);
        top: 0.12rem;
        height: 0.16rem;
        width: 0.16rem;
        background: #666;
        transform: scale(0.5);
        transform-origin: 50% 0
          /*rtl:ignore*/
        ;
        border-radius: 50%;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 0.64rem;
        max-width: 92%;
      }

      .over-text {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    .countdown-range {
      &:before {
        top: .2rem;
        background: #FA6338;
        /*rw:begin*/
        background: #666666;
      }
    }

    li:not(:first-child) {
      margin-top: 0.107rem;
    }

    .over-text {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-height: 0.365rem;
    }
  }

  &.prime-coupon {
    background: linear-gradient(0deg, #FFE2D3, #FFE2D3), #FFFFFF;
    border-radius: .1333rem;
    border: none;

    &:before {
      height: .1067rem;
      background-color: #DA7E50;
      border-radius: .2667rem .2667rem 0 0;
    }

    .count {
      background-color: #DA7E50;
      border-radius: 0 .1067rem 0 0;
      z-index: 1;

      &:before {
        border-color: #DA7E50 transparent transparent;
        border-right: .1067rem solid #DA7E50;
      }
    }

    .head {
      color: rgba(135, 60, 0, 1);
    }

    .body {
      li {
        color: rgba(135, 60, 0, .6);

        &:before {
          background-color: rgba(135, 60, 0, .6);
        }
      }
    }

    .coupon-mid-line {
      &>div {
        background-image: linear-gradient(to right,
            rgba(135, 60, 0, .6) 0%,
            rgba(135, 60, 0, .6) 50%,
            transparent 50%);
      }
    }
  }

  &.prime-coupon__max {
    &:before {
      background-color: #FF7F46;
    }

    .max-count {
      background: linear-gradient(92deg, #FF7F46 3.07%, #FF345B 99.91%);
      color: #FFE2D3;
      font-size: 12px;
      right: 0px;
      top: 0px;

      &:before {
        border-color: #FF7F46 transparent transparent;
        border-right: .1467rem solid #FF7F46;
      }
    }

    .head {
      padding: 0;
    }

    .header-wrapper,
    .head-column,
    .head-row {
      padding: 0.534rem 0.427rem .21rem;
    }

    .body {
      padding: 0;
    }

    .tips-wrapper {
      width: 100%;
      padding: 7/75rem 0.427rem 0.2667rem;
    }
  }

  &.prime-coupon_white {
    background: #FFF0E9;
    border-radius: 0;
    border-width: .0133rem;
    border-style: solid;
    border-color: #FFE2CF;

    &:before,
    .count {
      border-radius: 0;
    }

    .coupon-mid-line {
      &>div {
        background-image: linear-gradient(to right,
            rgba(135, 60, 0, .2) 0%,
            rgba(135, 60, 0, .2) 50%,
            transparent 50%);
      }
    }
  }

  &.prime-coupon__logo {
    position: relative;
    border-radius: .16rem;
    border-width: 0;

    .shein-club {
      position: absolute;
      width: 1.7867rem;
      height: .4rem;
      background-color: #FFE2D3;
      border-bottom-right-radius: .08rem;
      top: .1rem;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.expired {
    border-width: .0133rem;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.16);
    background: rgba(204, 204, 204, 0.16);
    filter: grayscale(100%);

    &:before {
      background-color: #ccc
        /*rw: #e5e5e5*/
      ;
    }

    .count {
      background: #ccc
        /*rw: #e5e5e5*/
      ;
      color: white
        /*rw: #999*/
      ;

      &::before {
        border-top-color: #ccc;
        .border-r(0.107rem, #ccc);
        /*rw:begin*/
        border-top-color: #e5e5e5;
        .border-r(0.107rem, #e5e5e5);
      }
    }

    .head-right {
      border-color: #ccc !important; /* stylelint-disable-line declaration-no-important */
    }

    .head {
      color: #bbb;

      .expired-btn {
        background-color: #e5e5e5;
        color: #666;
        border: none;
        font-weight: 400;
        text-transform: initial;
        pointer-events: none;
      }
    }

    .body {

      li,
      .c-icon-tip {
        color: #bbb;
      }

      li::before {
        background-color: #bbb;
      }
    }

    .coupon-mid-line {
      &>div {
        background-image: linear-gradient(to right,
            #e5e5e5 0%,
            #e5e5e5 50%,
            transparent 50%);
      }
    }

    .shiping-coupon-tip {
      background-color: #BBBBBB;
      color: #fff;
    }
  }

  .bg-wrapper {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .coupon__newly-get {
    position: absolute;
    .left(-1.0133rem);
    top: .16rem;
    z-index: 1;
    width: 2.6667rem;
    height: .4533rem;
    font-size: 12px;
    line-height: .4533rem;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(12, 62, 16, 0.12);
    color: #fff;
    background-color: #3CBD45;
    transform: rotate(-45deg) scale(.8333);
    /*rw:begin*/
    color: #222;
    font-weight: 700;
    background-color: #83FFCC;
  }
}

.c-coupon-item {
  &__for-prime {
    border: 1/75rem solid #FFE2CF;
    background: linear-gradient(270deg, #FFF0E8 0%, #FFF 70.69%);

    &:before {
      background-color: #C96E3F;
    }

    .head {
      color: #873C00;
      padding-top: 28/75rem;
      .coupon-countdown {
        color: #873C00;
      }
      .coupon-countdown__time > span {
        background-color: #873C00;
      }
    }

    .coupon-mid-line {
      & > div {
        background-image: linear-gradient(to right,
          #fff 0%,
          #fff 50%,
          transparent 50%); 
      }
    }

    .body li {
      color: #873C00;
      &:before {
        background: #873C00;
      }
    }

    .count {
      background-color: #C96E3F;

      &:before {
        border-color: #C96E3F transparent transparent;
        border-right: 8/75rem solid #C96E3F;
      }
    }

  }

  &__for-saver {
    border: 1/75rem solid #FEE3ED;

    &:before {
      background-color: #F82854;
    }

    .head {
      color: #F82854;
      padding-top: 28/75rem;
      .coupon-countdown {
        color: #F82854;
      }
      .coupon-countdown__time > span {
        background-color: #F82854;
      }
    }
    
    .coupon-mid-line {
      & > div {
        background-image: linear-gradient(to right,
          #fff 0%,
          #fff 50%,
          transparent 50%);
      }
    }

    .body li {
      color: #801A30;
      &:before {
        background: #801A30;
      }
    }

    .count {
      background-color: #F82854;

      &:before {
        border-right-color: #F82854;
        border-color: #F82854 transparent transparent;
      }
    }
  }

  &__logo {
    width: 210/75rem;
    height: 188/75rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.hide-coupon-header {
  border-top: 0.5px solid var(--borderColor, #ffe2cf);
  &:before {
    display: none;
  }
}
</style>
