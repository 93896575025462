<template>
  <div
    class="head-column"
    :class="{ head: true, noBtn: item.is_bind === 0 }"
  >
    <div class="head-column_top">
      <div class="head-left">
        <template
          v-for="(rule, index) in item.coupon_rule"
          :key="`coupon-rule-${index}`"
        >
          <div
            class="sub-item"
          >
            <coupon-sub-title
              :is-new-user="isNewUser"
              :item="item"
              :coupon-rule="rule"
              :language="language"
              :index="index"
              :is-satisfied-range="isSatisfiedRange"
              :is-show-free-gift="isShowFreeGift"
              :direction-type="directionType"
              :coupon-type="couponType"
              v-bind="componentAttrs"
              :is-new-style="isNewStyle"
            />
          </div>
        </template>
      </div>

      <div
        v-if="isShow"
        ref="headRightRef"
        class="head-right"
      >
        <slot name="handle"></slot>

        <time-tips
          v-if="showTimeTips"
          :item="item"
          :page="page"
          v-bind="componentAttrs"
          :language="language"
          :coupon-type="couponType" 
        />
      </div>
    </div>

    <!-- 优惠券信息 -->
    <slot name="main"></slot>

    <div class="head-column__labels">
      <!-- 可叠加标识（下单页多档券） -->
      <span v-if="item.coupon_rule.length > 1 && stageable">
        {{ language.SHEIN_KEY_PWA_20999 }}
      </span>
    </div>
    
    <coupon-categories
      v-if="isNewStyle && item.category_name.length"
      class="head-column__categories"
      :item="item" 
    />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import timeTips from '../timeTips.vue'
import couponSubTitle from './couponSubTitle.vue'
import couponCategories from './couponCategories.vue'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

export default defineComponent({
  name: 'HeadColumn',
  components: { timeTips, couponSubTitle, couponCategories },
  props: {
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    isSatisfiedRange: {
      type: Boolean,
      default: false
    },
    isShowFreeGift: { // 是否展示赠品提示free gift
      type: Boolean,
      default: true
    },
    directionType: { // 多档券排列顺序
      type: String,
      default: 'column'
    },
    showCoupondown: {
      type: Boolean,
      default: false
    },
    isShowCountdownFlag: {
      // 是否展示倒计时标识
      type: Boolean,
      default: false,
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false,
    },
    updateStyle: {
      type: Boolean,
      default: false
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    stageable: { // 是否可叠加
      type: Boolean,
      default: false
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isShow: true
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
    showTimeTips() {
      // 店铺页未开始券
      if (this.item.timeStatus === '1' && this.page === 'userCoupon') {
        return !this.isShowCountdownFlag
      }
      // 倒计时优先级更高
      return (!this.showCoupondown || !this.forRevisitComponent) && !this.isShowCountdownFlag
    }
  },
  watch: {
    updateStyle (val) {
      if (val) {
        this.isShow = true
        this.init()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      nextTick(() => {
        let fontSize = 37.5
        if (window.document?.documentElement?.style?.fontSize) {
          fontSize = parseFloat(
            window.document.documentElement.style.fontSize
          )
        }
        const clientWidth = this.$refs.headRightRef.clientWidth
        if (clientWidth <= Math.ceil(0.39 * fontSize)) {
          this.isShow = false
        }
      })
    }
  },
})
</script>

<style lang="less">
.head-column {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .head-column_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sub-item {
      margin-bottom: .426rem;
    }
  }
  .head-right {
    max-width: 50%;
    .padding-l(0.39rem);
    height: 2.12rem;
    justify-content: center;
    .border-l-line(1px, @sui_color_promo, dashed);
  }

  &__labels {
    align-self: flex-start;
    > span {
      font-family: SF UI Display;
      .font-dpr(20px);
      padding: 0 8/75rem;
      border-radius: 1px;
      border: 1px solid rgba(51, 126, 255, 0.20);
      line-height: 32/75rem;
      font-weight: 400;
      white-space: nowrap;
      background-color: @sui_color_note_light_bg;
      color: @sui_color_link;
    }
  }

  &__categories {
    align-self: flex-start;
  }
}
.head-column.noBtn{
   .head-right {
     border-left: none;
   }
}
.expired {
  .head-right {
    border-color: #ccc;
  }
}
</style>
