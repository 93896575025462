<template>
  <!-- 优惠券顶部优惠信息，门槛等 券上数据 -->
  <section
    class="title-section"
    :class="[couponType == 'revisit-coupon' && 'revisit-sub-title', isNewUser && 'is-new-user']"
  >
    <div 
      class="coupon-title-warp"
      :class="{
        'fade-in-down': item.expandStatus == 'seal'
      }"
    >
      <div
        class="coupon-title__text"
        :style="{
          '--text-indent': !showWraperImg ? 0 : ''
        }"
      >
        <!-- 超省卡券标识 -->
        <i
          v-if="item.real_type_id == '55' || item.real_type_id == '59'"
          class="icon_saver"
          :style="{
            background: `url(${PUBLIC_CDN}/pwa_dist/images/coupon_box/${isExpired ? 'sui_icon_saver_disable-39f4cc3b3d' : 'icon_saver-a30c7d2571'}.png)`,backgroundSize: 'cover'
          }"
        ></i>
        <!-- 付费会员券标识 -->
        <i
          v-if="(showWraperImg && item.real_type_id == '53') || item.real_type_id == '58'"
          class="wraper-img suiiconfont sui_icon_club_logo_fill_18px"
          style="color: #873C00"
        ></i>

        <!-- ab价 -->
        <span
          v-if="item.apply_for == 101"
          class="title"
          :class="{
            'coupon-title__text_indent': [53, 55, 58, 59].includes(+item.real_type_id)
          }"
          :style="{'font-size': ruleLen > 2 && '12px'}"
        >
          {{ item.coupon_rule[0].value_amount.amountWithSymbol }} {{ language.SHEIN_KEY_PWA_25739 }}
        </span>
        <template v-else>
          <!-- free shipping -->
          <span
            v-if="(item.real_type_id == '53' || item.real_type_id == '55' || item.apply_for == '9') && item.shipping_discount_type == 0"
            class="title"
            :class="{
              'coupon-title__text_indent': [53, 55, 58, 59].includes(+item.real_type_id),
            }"
            :style="{'font-size': ruleLen > 2 && '12px'}"
          >
            {{ language.SHEIN_KEY_PWA_15807 }}
          </span>
          <!-- 实际优惠信息 -->
          <span
            v-else
            :id="`title-${item.coupon}-${index}`"
            tabindex="0"
            :style="{'font-size': ruleLen > 2 && '12px'}"
            class="title"
            :class="{
              'coupon-title__text_indent': [53, 55, 58, 59].includes(+item.real_type_id),
            }"
          >
            <template v-if="['5', '6'].includes(item.apply_for)">
              {{ language.SHEIN_KEY_PWA_15807 }}
            </template>
            <template v-else-if="item.coupon_type_id == '1'">
              {{
                directionType == 'row'
                  ? `- ${couponRule.value.amountWithSymbol}`
                  : template(
                    couponRule.value.amountWithSymbol,
                    getTitleLanguage(couponRule)
                  )
              }}
            </template>
            <template v-else>
              {{
                template(
                  percentFormat(couponRule.value.amount),
                  getTitleLanguage(couponRule)
                )
              }}
            </template>
          </span>
        </template>
      </div>
      <!-- 付费会员邮费部分抵扣标识 -->
      <span
        v-if="(item.real_type_id == '53' || item.real_type_id == '55' || item.apply_for == '9') && item.shipping_discount_type == 1"
        class="shiping-coupon-tip"
        :class="{
          'is-green': isGreenTheme
        }"
      >
        {{ isGreenTheme ? language.SHEIN_KEY_PWA_29122 : language.SHEIN_KEY_PWA_22479 }}
      </span>
      <div class="coupon-title__labels">
        <!-- 最优券标识（购物车） -->
        <span
          v-if="isShowBest && item.is_best == 1 && (index + 1 ) == item.satisfied_range" 
          class="best-coupon"
        >
          <i class="suiiconfont sui_icon_like_completed_16px"></i>
          <span>{{ language.SHEIN_KEY_PWA_24708 }}</span>
        </span>
        <!-- 可叠加标识（下单页单档券） -->
        <span
          v-if="item.coupon_rule.length <= 1 && stageable"
          class="stackable-coupon-tip"
        >
          {{ language.SHEIN_KEY_PWA_20999 }}
        </span>
      </div>
    </div>

    <!-- 优惠券门槛信息 -->
    <p :style="{'line-height':couponType == 'revisit-coupon' && '20px','color': IS_RW && !isGreenTheme ? '#fc4070' : ''}">
      {{ couponSubTitle }}
    </p>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
const { IMG_LINK } = gbCommonInfo
import { htmlDecode, template } from '@shein/common-function'
import { getTextLline } from '../utils'
const { IS_RW, PUBLIC_CDN, GB_cssRight } = gbCommonInfo
export default defineComponent({
  name: 'CouponSubTitle',
  emits: ['update:titleLength'],
  props: {
    ruleLen: {
      type: Number,
      default: 0
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    // isSaverCoupon: {
    //   type: Boolean,
    //   default: false
    // },
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null,
    },
    couponRule: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: null,
    },
    titleLength: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    isSatisfiedRange: {
      type: Boolean,
      default: false
    },
    isShowFreeGift: {
      type: Boolean,
      default: true
    },
    directionType: { // 多档券排列顺序
      type: String,
      default: 'column'
    },
    isOrderReturn: {
      type: Boolean,
      default: false
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    isShowBest: {
      type: Boolean,
      default: false,
    },
    abtEstimated: { // abt控制参数, 是否命中到手价实验.如果命中，SHEIN_KEY_PWA_15817 要换成 SHEIN_KEY_PWA_25377。
      type: Boolean,
      default: false
    },
    mustShowMin: {
      type: Boolean,
      default: true
    },
    stageable: { // 是否可叠加
      type: Boolean,
      default: false
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
    isGreenTheme: { // 是否命中商详绿色主题免邮券
      type: Boolean,
      default: false
    },
    showWraperImg: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      IMG_LINK,
      IS_RW,
      PUBLIC_CDN,
    }
  },
  computed: {
    // 优惠券门槛信息
    couponSubTitle() {
      const min = this.couponRule.min
      const max = this.couponRule.max
      const language = this.language
      if (this.item.real_type_id == '58') return this.template(
        min.amountWithSymbol,
        this.language.SHEIN_KEY_PWA_28755
      )
      if (this.item.real_type_id == '59') return this.template(
        min.amountWithSymbol,
        this.language.SHEIN_KEY_PWA_28743
      )
      if(gbCommonInfo?.templateType === 'paySuccess'){
        // 处理支付成功页多档券，SHEIN_KEY_PWA_24869
        const currentPage = gbCommonInfo?.templateType === 'paySuccess'
        return this.template(
          min.amountWithSymbol,
          this.htmlDecode({ text: this.language[currentPage ? 'SHEIN_KEY_PWA_24869' : this.isOrderReturn ? 'SHEIN_KEY_PWA_24357' : (this.abtEstimated ? 'SHEIN_KEY_PWA_25377' : 'SHEIN_KEY_PWA_15817')] })
        )
      }else {
        if(this.isNewUser && this.item.coupon_rule.length > 1){
          // 新客优惠券弹框二挡，三挡，采用 order $100 +
          return this.template(
            min.amountWithSymbol,
            this.htmlDecode({ text: this.language['SHEIN_KEY_PWA_24865'] + '+' })
          )
        }else {
          if (max && max.amountWithSymbol) {
            return this.template(
              min.amountWithSymbol,
              max.amountWithSymbol,
              this.htmlDecode({ text: language.SHEIN_KEY_PWA_18572 })
            )
          }

          const mustShowMin = !this.isNewStyle && this.mustShowMin
          if(min?.amount > 0 || mustShowMin){
            const minKey = this.isNewStyle ? 'SHEIN_KEY_PWA_28877' : 'SHEIN_KEY_PWA_15817'
            return this.template(
              min.amountWithSymbol,
              this.htmlDecode({ text: this.language[this.isOrderReturn ? 'SHEIN_KEY_PWA_24357' : (this.abtEstimated ? 'SHEIN_KEY_PWA_25377' : minKey)] })
            )
          } else if (Number(min?.amount) === 0 && this.item.apply_for !== 101) {
            // 无门槛券，且非ab价 展示文案
            const noThresholdKey = this.isNewStyle ? 'SHEIN_KEY_PWA_28878' : 'SHEIN_KEY_PWA_27118'
            return this.language[noThresholdKey] || ''
          } else {
            return ''
          }
        }
      }
    }
  },
  mounted () {
    const el = $(`#title-${this.item.coupon}-${this.index}`)
    if (el.length) {
      const textLine = getTextLline(el)
      textLine > 1 && this.$emit('update:titleLength', textLine)
    }
    this.textOver7()
  },
  methods: {
    htmlDecode,
    template,
    textOver7(){
      const el = $(`#title-${this.item.coupon}-${this.index}`)
      if(el[0]?.innerText?.length > 9 && this.ruleLen >= 2) {
        el[0].style.fontSize = '0.32rem'
        el[0].style.whiteSpace = 'normal'
      }
    },
    // 获取标题文案
    getTitleLanguage (couponRule) {
      if (this.item.real_type_id == '53') return '{0}'
      if (this.item.real_type_id == '58') return this.language.SHEIN_KEY_PWA_28754
      if (this.item.real_type_id == '59') return this.language.SHEIN_KEY_PWA_28742
      if (!this.isShowFreeGift) return this.htmlDecode({ text: this.language.SHEIN_KEY_PWA_15816 })
      if (this.isSatisfiedRange) {
        return this.htmlDecode({
          text: (this.index <= (this.item.satisfied_range - 1)) && couponRule.coupon_gift_id
            ? this.language.SHEIN_KEY_PWA_18334
            : this.language.SHEIN_KEY_PWA_15816
        })
      }
      return this.htmlDecode({
        text: couponRule.coupon_gift_id
          ? this.language.SHEIN_KEY_PWA_18334
          : this.language.SHEIN_KEY_PWA_15816
      })
    },
    // 百分比格式化
    percentFormat (num) {
      const newNum = (num * 100) / 100
      const percentNum = GB_cssRight ? `%${newNum}` : `${newNum}%`
      return percentNum
    }
  },
})
</script>

<style lang="less">
.icon_saver {
  width: .5333rem;
  height: .5333rem;
}
.title-section {
  width: 100%;
}
.coupon-title-warp {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.wraper-img {
  display: inline-block;
}

.coupon-title {
  &__text {
    display: flex;
    position: relative;

    .icon_saver,
    .wraper-img {
      position: absolute;
      top: 0;
      left: 0;
    }

    &_indent {
      text-indent: var(--text-indent, .6rem);
    }
  }
}
.shiping-coupon-tip {
  font-size: 12px;
  line-height: .4rem;
  padding: .04rem .1067rem;
  background: rgba(255, 186, 151, 0.6);
  transform: scale(.83);
  font-weight: 400;
  margin-left: 0.12rem;
  transform-origin: center left;
  &.is-green{
    background-color: rgba(22, 158, 0, 0.10);
  }
}
.coupon-title__labels {
  display: flex;
}
.stackable-coupon-tip {
  font-family: SF UI Display;
  .font-dpr(20px);
  padding: 0 8/75rem;
  border-radius: 1px;
  border: 1px solid rgba(51, 126, 255, 0.20);
  line-height: 32/75rem;
  font-weight: 400;
  margin-left: 8/75rem;
  white-space: nowrap;
  background-color: @sui_color_note_light_bg;
  color: @sui_color_link;
}
.best-coupon {
  border-radius: 1px;
  border: 1px solid fade(@sui_color_welfare, 20%);
  display: flex;
  height: 32/75rem;
  padding: 3/75rem 8/75rem;
  margin-left: 10/75rem;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: @sui_color_welfare;
  font-size: 10px;
  font-weight: 400;
  i {
    margin-right: 4/75rem;
    font-size: 10px !important; /* stylelint-disable-line declaration-no-important */
    width: 10px;
    height: 10px;
    line-height: 10px;
    color: @sui_color_welfare
  }
}
.revisit-sub-title{
  /*sh:begin*/
  color: #FA6338;
  /*rw:begin*/
  color: #FC4070;

}
.fade-in-down {
  animation: fade-in-down 2s ease both;
  animation-delay: 3s;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
